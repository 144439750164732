
/**
 * Needs shared dragscroll plugin
 * @link https://github.com/donmbelembe/vue-dragscroll
 */
import { debounce, throttle } from 'lodash'
import { mapFields } from 'vuex-map-fields'
import Vue from 'vue'

/**
 * Calculate outer width
 * @param el
 * @return {number}
 */
function outerWidth(el: HTMLElement) {
  let width = el.offsetWidth
  let style = getComputedStyle(el)
  width += parseInt(style.marginLeft) + parseInt(style.marginRight)
  return width
}

export default Vue.extend({
  name: 'HorizontalScroll',
  props: {
    /**
     * Manual scrolling steps in px
     */
    scrollStep: {
      type: Number,
      default: 100,
    },
    smoothScrollH: {
      type: Boolean,
      default: true,
    },
    /**
     * Add vertical padding
     */
    paddingVertical: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scrolledToLeft: true,
      scrolledToRight: false,
      resizeSensor: null as ResizeObserver | null,
      smoothScroll: true,
      allowedOffset: 2,
    }
  },
  computed: {
    ...mapFields('ux', { isTouch: 'isTouch' }),
  },
  mounted() {
    let el = this.$refs.scrollContainer as HTMLElement
    // handle scrolling
    el.addEventListener('scroll', this.handleScroll)
    // handle resizing
    if (typeof ResizeObserver !== undefined) {
      this.resizeSensor = new ResizeObserver(this.updateSync)
      this.resizeSensor.observe(el)
    }
    // react to manually emitted events ($refs.scroller.$emit('update'))
    // this.$on('update', this.updateData)
    // init
    setTimeout(() => {
      this.updateSync()
    }, 500)
  },
  beforeDestroy() {
    let el = this.$refs.scrollContainer as HTMLElement
    if (el) {
      el.removeEventListener('scroll', this.handleScroll)
      if (this.resizeSensor) {
        this.resizeSensor.unobserve(el)
        this.resizeSensor.disconnect()
      }
    }
  },
  methods: {
    updateSync() {
      let el = this.$refs.scrollContainer as HTMLElement
      if (el) {
        this.updateData(el)
      }
    },
    /**
     * Update scroll position
     */
    handleScroll() {
      // update scroll position
      let el = this.$refs.scrollContainer as HTMLElement
      if (el) {
        this.throttleHandleScroll(this, el)
        this.debounceHandleScrollEnd(this, el)
      }
    },
    /**
     *  Handle scroll by throttling the function
     */
    throttleHandleScroll: throttle((vm, el) => {
      vm.updateData(el)
    }, 100),
    /**
     *  Handle scroll end by debouncing the function
     */
    debounceHandleScrollEnd: debounce((vm, el) => {
      vm.updateData(el)
    }, 200),
    updateData(el: HTMLElement) {
      let width = outerWidth(el) // visible width
      let scrollWidth = el.scrollWidth // total width
      let scrollLeft = el.scrollLeft // width scrolled to left
      // update booleans
      this.scrolledToRight =
        scrollLeft >= scrollWidth - width - this.allowedOffset
      this.scrolledToLeft = scrollLeft <= this.allowedOffset
    },
    /**
     * Scroll to left
     */
    scrollLeft() {
      let el = this.$refs.scrollContainer as HTMLElement
      if (el && !this.scrolledToLeft) {
        let scrollAmount = Math.min(el.scrollLeft, this.scrollStep)
        el.scrollLeft -= scrollAmount
      }
    },
    /**
     * Scroll to right
     */
    scrollRight() {
      let el = this.$refs.scrollContainer as HTMLElement
      if (el && !this.scrolledToRight) {
        let overflowWidth = Math.max(
          el.scrollWidth - outerWidth(el) - el.scrollLeft,
          0
        )
        let scrollAmount = Math.min(overflowWidth, this.scrollStep)
        el.scrollLeft += scrollAmount
      }
    },
  },
})
