import getAddressJsonld from './getAddressJsonld'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'

/**
 * Get organization structured data
 *
 * @link https://schema.org/Organization
 * @param organization
 */
export default function (organization: Organization): Record<string, any> {
  if (!organization) return {}
  const organizationJsonld: Record<string, any> = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: organization.name,
    email: organization.supportEmail,
    address: getAddressJsonld(organization.location),
  }

  // logo image
  if (organization.logoImage) {
    organizationJsonld.logo = organization.logoImage.smallPath
  }

  return organizationJsonld
}
