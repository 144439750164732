
import Vue from 'vue'
import { handleError } from '@/shared/utils/handleError'
import getOrganizationJsonld from '@/shared/seo/getOrganizationJsonld'
import { encodeQueryData } from '@/shared/utils/Helpers'
import { ContextNavigationItem } from '@/bookingbuddy-shop/store/ux'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import ResponsiveImage from '@/shared/components/media/ResponsiveImage.vue'
import { MetaInfo } from 'vue-meta'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import { ResourceCollection } from '@anny.co/vue-jsonapi-orm'
import { Platform } from '@/shared/jsonapi-orm/bookingbuddy/Platform'
import TabBar, { TabBarOption } from '@/shared/components/navigation/TabBar.vue'
import useShopLocale from '@/bookingbuddy-shop/composables/shopLocale'

export default Vue.extend({
  name: 'OrganizationPage',
  components: {
    ResponsiveImage,
    TabBar,
  },
  beforeRouteLeave(to, from, next) {
    if (this.getRouteBaseName(to) !== 'book-resourceSlug') {
      this.$store.commit('ux/setContextNavigationItem', null)
    }
    next()
  },
  layout(context) {
    return context.$shopService.layout
  },
  /**
   * @param {Context} context
   */
  async asyncData(context) {
    let {
      params: { organizationSlug },
      route,
      app,
      redirect,
    } = context

    let organizationId = ''
    let previewToken = String(route.query.preview_token ?? '')
    // Try to request the organization and resources
    try {
      const organization = await Organization.api(context.$jsonApiService)
        .with([
          'logoImage',
          'coverImage',
          'location',
          'resourceGroups',
          'offeredPlans',
          'offeredBookingBundles',
        ])
        .query({
          preview_token: previewToken,
        })
        .find(organizationSlug)

      // redirect to correct platform
      if (
        organization.platformId &&
        organization.platformId !== context.$config.activePlatformId
      ) {
        const platform = await Platform.api(context.$jsonApiService).find(
          organization.platformId
        )
        return context.redirect(
          platform.config.shopBaseUrl + context.route.fullPath
        )
      }

      // redirect to default sub page on index route
      let subPages: Record<string, string> = {
        about: 'organizationSlug-about',
      }
      if (organization.metaSettings.listResources) {
        subPages['resources'] = 'organizationSlug-resources'
      }
      if (organization.metaSettings.listServices) {
        subPages['services'] = 'organizationSlug-services'
      }
      if (organization.metaSettings.listPlans) {
        subPages['plans'] = 'organizationSlug-subscriptions'
      }
      if (organization.metaSettings.listBookingBundles) {
        subPages['bookingBundles'] = 'organizationSlug-packages'
      }
      let subPageName = subPages.about
      if (organization.metaSettings.defaultList! in subPages) {
        subPageName = subPages[organization.metaSettings.defaultList!]
      }
      const routeBaseName = app.getRouteBaseName(route)
      if (
        subPageName !== routeBaseName &&
        routeBaseName === 'organizationSlug'
      ) {
        return redirect(
          app.localePath({
            name: subPageName,
            params: {
              organizationSlug: organization.slug,
            },
            query: { ...route.query },
          })
        )
      }

      // Switch primary key to slug
      organizationId = organization.id
      await Promise.all([
        organization.load('properties', [], true, {
          params: {
            preview_token: previewToken,
          },
        }),
        organization.load('used-categories', [], true, {
          params: {
            preview_token: previewToken,
          },
        }),
      ])

      const { setLocale } = useShopLocale(context, organization)
      await setLocale()

      // set navigation context
      context.store.commit('ux/setContextNavigationItem', {
        title: String(organization.name),
        prefix: organization.logoImage
          ? {
              imageUrl: organization.logoImage.smallPath,
            }
          : undefined,
      } as ContextNavigationItem)

      return {
        organizationId,
      }
    } catch (e) {
      handleError(context, e)
    }
  },
  data() {
    return {
      organizationId: '',
      organization: null as Organization | null,
      collection: new ResourceCollection<Resource>(
        Resource.api(this.$jsonApiService)
      ), // only dummy collection

      loading: false,
      /**
       * Available sort columns for resources
       */
      resourceColumns: [
        {
          name: this.$i18n.t('pages.organizations.index.resourceColumns.name'),
          key: 'name',
        },
      ],
    }
  },
  head(): MetaInfo {
    let organization: Organization | null = this.organization

    if (organization) {
      // prepare manifest
      let manifestParams = { ...this.$route.query }
      delete manifestParams.group
      delete manifestParams.category
      let manifestParamsString = encodeQueryData(manifestParams)
      // prepare image
      let imageUrl = organization.coverImage
        ? organization.coverImage.smallPath
        : ''
      let touchIcon =
        organization.logoImage?.icons?.['180x180'] ??
        '/_favicons/apple-touch-icon.png'

      return {
        title: organization.name,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: organization.plainDescription,
          },
          // open graph
          {
            hid: 'og:title',
            name: 'og:title',
            content: organization.name,
          },
          { hid: 'og:type', name: 'og:type', content: 'website' },
          {
            hid: 'og:description',
            name: 'og:description',
            content: organization.plainDescription,
          },
          { hid: 'og:image', name: 'og:image', content: imageUrl },
          { hid: 'og:url', name: 'og:url', content: this.url },
          // twitter
          {
            hid: 'twitter:card',
            name: 'twitter:card',
            content: 'summary_large_image',
          },
          {
            hid: 'twitter:image',
            name: 'twitter:image',
            content: imageUrl,
          },
          {
            hid: 'twitter:domain',
            name: 'twitter:domain',
            content: this.$config.baseUrl,
          },
          {
            hid: 'twitter:url',
            name: 'twitter:url',
            content: this.url,
          },
          {
            hid: 'twitter:title',
            name: 'twitter:title',
            content: organization.name,
          },
          {
            hid: 'twitter:description',
            name: 'twitter:description',
            content: organization.plainDescription,
          },
          // additional fields
          {
            hid: 'twitter:label1',
            name: 'twitter:label1',
            content: this.$i18n.t('pages.organizations.address'),
          },
          {
            hid: 'twitter:data1',
            name: 'twitter:data1',
            content: organization.location
              ? organization.location.addressLine
              : '',
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: this.url,
          },
          {
            hid: 'manifest',
            rel: 'manifest',
            href: `${this.$config.apiBaseUrl}/pwa/${organization.id}/manifest.json?${manifestParamsString}`,
            importance: 'high',
          },
          {
            hid: 'apple-touch-icon',
            rel: 'apple-touch-icon',
            href: touchIcon,
          },
          {
            hid: 'apple-touch-startup-image',
            rel: 'apple-touch-startup-image',
            href: touchIcon,
          },
        ],
      }
    }

    return {}
  },
  computed: {
    hideOrganizationHeader(): boolean {
      return this.$embedService?.hasModifier('hoh') ?? false
    },
    url(): string {
      return this.$config.baseUrl + this.$route.path
    },
    structuredData(): Record<string, any> {
      if (this.organization) {
        return getOrganizationJsonld(this.organization)
      }

      return {}
    },
    tabs(): TabBarOption[] {
      const tabs: TabBarOption[] = []
      const resourceTab: TabBarOption = {
        title: this.$i18n.t('pages.organizations.tabs.resources'),
        value: 'organizationSlug-resources',
        route: this.getRoutePath('organizationSlug-resources'),
        hidden: !this.organization?.metaSettings.listResources,
      }
      const serviceTab: TabBarOption = {
        title: this.$i18n.t('pages.organizations.tabs.services'),
        value: 'organizationSlug-services',
        route: this.getRoutePath('organizationSlug-services'),
        hidden: !this.organization?.metaSettings.listServices,
      }
      // apply order based on priority
      if (this.organization?.metaSettings.defaultList === 'services') {
        tabs.push(serviceTab, resourceTab)
      } else {
        tabs.push(resourceTab, serviceTab)
      }

      tabs.push(
        {
          title: this.$i18n.t('pages.organizations.tabs.subscriptions'),
          value: 'organizationSlug-subscriptions',
          route: this.getRoutePath('organizationSlug-subscriptions'),
          hidden:
            !this.organization!.offeredPlans ||
            this.organization!.offeredPlans.length === 0 ||
            !this.organization?.metaSettings.listPlans,
        },
        {
          title: this.$i18n.t('pages.organizations.tabs.bookingBundles'),
          value: 'organizationSlug-packages',
          route: this.getRoutePath('organizationSlug-packages'),
          hidden:
            this.organization?.offeredBookingBundles?.length === 0 ||
            !this.organization?.metaSettings.listBookingBundles,
        },
        {
          title: this.$i18n.t('pages.organizations.tabs.about'),
          value: 'organizationSlug-about',
          route: this.getRoutePath('organizationSlug-about'),
        }
      )
      return tabs
    },
    currentTab(): string {
      return String(this.getRouteBaseName(this.$route))
    },
  },
  created() {
    this.organization = Organization.fromId(
      this.organizationId,
      this.$jsonApiService
    )
  },
  async mounted() {
    // set last visited organization
    await this.$store.dispatch('tracking/visitOrganization', this.organization)
  },
  methods: {
    getRoutePath(routeName: string): string {
      let baseRoute: Record<string, any> = {
        name: routeName,
        params: {
          organizationSlug: this.organization?.slug,
        },
        query: {
          ...this.$route.query,
        },
      }
      if (routeName === 'organizationSlug-resources') {
        baseRoute = {
          ...baseRoute,
          query: { view: 'list', ...this.$route.query },
        }
      }
      return this.localePath(baseRoute)
    },
  },
})
