import { prefLocaleCookieName } from '@/shared/assets/i18n/i18nHelper'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { Context } from '@nuxt/types'

export default function useShopLocale(
  context: Context,
  organization: Organization
) {
  const setLocale = async () => {
    // check if a cookie is set
    const cookieName = context.$config.storagePrefix + prefLocaleCookieName
    const cookieExists = context.$cookies.get(cookieName)

    // if cookie exists, no need to change the locale
    if (cookieExists) {
      return
    }

    const { app } = context
    if (app.i18n.locale === app.i18n.defaultLocale) {
      // if current locale is '/' (de), apply standard locale of organization
      app.i18n.setLocale(organization?.defaultLocale ?? app.i18n.defaultLocale)
    }
  }

  return { setLocale }
}
