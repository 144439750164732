var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"responsive-image-container"},[_c('div',{staticClass:"responsive-image",class:[
      _vm.customClasses,
      {
        'responsive-blurred-image': _vm.blurred,
        'with-fixed-aspect-ratio': !_vm.useNaturalAspectRatio && _vm.aspectRatio,
      },
    ],style:(_vm.style)}),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.image && _vm.image.copyright && _vm.showCopyRight)?_c('div',{staticClass:"responsive-image-accessoire"},[_vm._v("\n    © "+_vm._s(_vm.image.copyright)+"\n  ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }