<template>
  <div class="responsive-image-container">
    <!-- Div background mode -->
    <div
      class="responsive-image"
      :class="[
        customClasses,
        {
          'responsive-blurred-image': blurred,
          'with-fixed-aspect-ratio': !useNaturalAspectRatio && aspectRatio,
        },
      ]"
      :style="style"
    ></div>
    <!-- Custom Overlay here -->
    <slot />
    <!-- Copyright notice -->
    <div
      v-if="image && image.copyright && showCopyRight"
      class="responsive-image-accessoire"
    >
      © {{ image.copyright }}
    </div>
  </div>
</template>

<script>
import { loadImage, imageLoaded } from '../../utils/MediaHelpers'

export default {
  name: 'ResponsiveImage',
  props: {
    customClasses: {
      type: String,
      default: '',
    },
    image: {
      type: Object,
      default: undefined,
    },
    height: {
      type: Number,
      required: false,
      default: undefined,
    },
    aspectRatio: {
      type: String,
      default: '',
    },
    /**
     * Indicate to use full aspect ratio on image div
     */
    useNaturalAspectRatio: {
      type: Boolean,
      default: false,
    },
    showCopyRight: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      blurred: true,
      currentSizePath: '',
      url: '',
      loaded: false,
    }
  },
  computed: {
    /**
     * Check if any image size is loaded
     * @return {boolean}
     */
    isAnyLoaded() {
      if (this.image) {
        for (let sizePath of [
          'small_path',
          'medium_path',
          'large_path',
          'url',
        ]) {
          let url = this.image[sizePath]
          if (url && imageLoaded(url)) {
            return true
          }
        }
      }
      return false
    },
    naturalAspectRatio() {
      if (this.image && this.image.width && this.image.height) {
        return this.image.width / this.image.height
      } else {
        return 16 / 9
      }
    },
    appliedAspectRatio() {
      if (this.aspectRatio === '16/9') {
        return '56.25%'
      } else if (this.aspectRatio === '1/1') {
        return '100%'
      }

      return '100%'
    },
    style() {
      let naturalWidth = Number(this.naturalAspectRatio * this.height).toFixed(
        0
      )
      return {
        '--aspect-ratio': this.appliedAspectRatio,
        minHeight: this.height ? this.height + 'px' : 'auto',
        height: this.height,
        width: this.useNaturalAspectRatio ? naturalWidth + 'px' : '100%',
        backgroundImage: this.url ? 'url(' + this.url + ')' : undefined,
        backgroundColor: 'var(--panel-background-dark)',
      }
    },
  },
  created() {
    // prevent transition when image already loaded
    if (this.isAnyLoaded) {
      this.blurred = false
    }
    // display blurred preview if available
    else if (this.image && this.image.preview_image_base64) {
      this.setImageUrl(this.image.preview_image_base64)
    }
  },
  mounted() {
    // handle image placeholder
    if (this.image === null || this.image === undefined) {
      this.blurred = false
      return
    }
    // initial image mount
    this.currentSizePath = this.getCurrentSizePath()
    this.mountImageForCurrentSize()
    // update image size on window resize
    window.addEventListener('resize', this.handleWindowResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  methods: {
    handleWindowResize() {
      let newSizePath = this.getCurrentSizePath()
      let sizes = ['small', 'medium', 'large']
      // check if larger image is required
      if (sizes.indexOf(newSizePath) > sizes.indexOf(this.currentSizePath)) {
        this.currentSizePath = newSizePath
        this.mountImageForCurrentSize()
      }
    },
    /**
     * Get image path for div size
     */
    getCurrentSizePath() {
      let width = this.$el.offsetWidth
      let sizePath = 'large_path'
      // select image size
      if (width < 500) {
        sizePath = 'small_path'
      } else if (width < 800) {
        sizePath = 'medium_path'
      }
      return sizePath
    },
    /**
     * Load current image size and
     * set style and image url for current size
     */
    async mountImageForCurrentSize() {
      let url = this.image[this.currentSizePath]
      // load image async and react to finished loading
      try {
        if (!imageLoaded(url)) {
          await loadImage(url)
        }
        this.blurred = false
        this.setImageUrl(url)
        this.loaded = true
        this.$emit('loaded', url)
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * Save image url and update style
     */
    setImageUrl(url) {
      this.url = url
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/sass/mixins';

$blur-radius: 8px;

.responsive-image {
  --aspect-ratio: 56.25%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  -webkit-transform: scale(1);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: 0.5s filter, 0.5s transform;
  // for aspect ratio
  position: relative;

  &.with-fixed-aspect-ratio {
    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: var(--aspect-ratio);
    }

    > .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.responsive-blurred-image {
  -webkit-filter: blur($blur-radius);
  filter: blur($blur-radius);
  transform: scale(1);
}

.responsive-image-container {
  height: 100%;
  position: relative;
  border-radius: inherit;
  mask-image: radial-gradient(white, black);
  overflow: hidden;

  .responsive-image-accessoire {
    position: absolute;
    right: 0;
    bottom: 0;
    border-top-left-radius: 6px;
    background-color: var(--panel-background-overlay-dense);
    padding: 1px 3px;
    font-size: 10px;
    color: var(--text-primary-color);
  }
}
</style>
