/**
 * Get JSON ld for postal address
 *
 * @link https://schema.org/PostalAddress
 * @param address
 * @return {*}
 */
import { Address } from '@/shared/jsonapi-orm/common/Address'

export default function (
  address: Address | undefined | null
): Record<string, any> {
  return address
    ? {
        '@type': 'PostalAddress',
        streetAddress: address.streetAddress,
        addressLocality: address.city,
        postalCode: address.zipCode,
        addressCountry: address.countryCode,
      }
    : {}
}
